import React, { useState } from 'react';
import JSZip from 'jszip';
import { htmlToText } from 'html-to-text';

const FileUploader = () => {
    const [characterCount, setCharacterCount] = useState(null);
    const [ognCost, setOgnCost] = useState(0);
    const [emnCost, setEmnCost] = useState(0);
    const [imageCount, setImageCount] = useState(0);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                const text = await extractTextFromDocx(file);
                setCharacterCount(text.length);
                calculateCosts(text.length);
                countImages(file);
            } catch (error) {
                console.error('Ошибка при обработке файла Word:', error);
            }
        }
    };

    const countImages = async (file) => {
        const zip = new JSZip();
        const zipData = await zip.loadAsync(file);
        const mediaFolder = zipData.folder('word/media');

        let imageCount = 0;

        if (mediaFolder) {
            for (const fileName of Object.keys(mediaFolder.files)) {
                if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.gif')) {
                    imageCount++;
                }
            }
        }

        setImageCount(imageCount);
    };

    const extractTextFromDocx = (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const zip = new JSZip();
                const zipData = await zip.loadAsync(file);
                const contentXml = await zipData.file('word/document.xml').async('text');
                const plainText = htmlToText(contentXml, {
                    wordwrap: false,
                    ignoreHref: true,
                    ignoreImage: true,
                });
                resolve(plainText);
            } catch (error) {
                reject(error);
            }
        });
    };

    const calculateCosts = (characterCount) => {
        const ognPricePerCharacter = 11887 / 16;
        const emnPricePerCharacter = 15849 / 16;

        const ognTotalCost = (characterCount / 2699) * ognPricePerCharacter;
        const emnTotalCost = (characterCount / 2699) * emnPricePerCharacter;

        setOgnCost(ognTotalCost.toFixed(2));
        setEmnCost(emnTotalCost.toFixed(2));
    };

    return (
        <div>
            <div className='inputBlock'>
                <label className="custom-file-label" htmlFor="customFileInput">
                    Загрузить файл
                </label>
                <input id="customFileInput"
                    type="file"
                    className="custom-file-input"
                    onChange={handleFileUpload} />
            </div>
            <div className='infoContainer'>
                {characterCount !== null && (
                    <div className='statBlock'>
                        <img src="" alt="" />
                        <span>Количество символов: {characterCount}</span>
                    </div>
                )}
                {characterCount !== null && (
                    <div className='statBlock'>
                        <img src="" alt="" />
                        <span>Количесво страниц: {(characterCount / 2699).toFixed(1)}</span>
                    </div>
                )}
                {ognCost !== 0 && (
                    <div className='statBlock'>
                        <img src="" alt="" />
                        <span>Стоимость ОГН: {ognCost} тг.</span>
                    </div>
                )}
                {emnCost !== 0 && (
                    <div className='statBlock'>
                        <img src="" alt="" />
                        <span>Стоимость ЕМН: {emnCost} тг.</span>
                    </div>
                )}
                {imageCount > 0 && (
                    <div className='statBlock'>
                        <img src="" alt="" />
                        <span>Количество изображений: {imageCount}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FileUploader